import { defineComponent } from '@vue/composition-api';
import Tip from '@/uikit/Tip.vue';
import BookingsAccordion from '@/Booking/components/BookingsAccordion.vue';
import BookingsSummary from '@/Booking/components/BookingsSummary.vue';
import PageLoadingBar from '@/shared/components/PageLoadingBar.vue';
import { useAllUserBookingsQuery } from '@/generated-types/graphql.types';
import { computed } from '@vue/composition-api';
import { mapBookings, getBookingsSummaryData, groupBookingsByDate } from '@/Booking/utils/bookings';
export default defineComponent({
    name: 'CustomerBookings',
    components: {
        Tip,
        BookingsSummary,
        BookingsAccordion,
        PageLoadingBar
    },
    setup() {
        const { result, loading } = useAllUserBookingsQuery({
            fetchPolicy: 'no-cache'
        });
        const bookingsByType = computed(() => mapBookings(result?.value?.get_bookings));
        const userHasBookings = computed(() => {
            return !!result.value?.get_bookings;
        });
        return {
            accordionBookingsData: computed(() => groupBookingsByDate(bookingsByType.value)),
            bookingsSummaryData: computed(() => getBookingsSummaryData(bookingsByType.value)),
            loading,
            userHasBookings
        };
    }
});
