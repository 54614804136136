import { isDateToday } from '@/util/dates';
import { defineComponent, computed } from '@vue/composition-api';
import BookedItemDetails from '@/Booking/components/BookedItemDetails.vue';
import { BookingDetailsViewMode } from '@/Booking/booking.const';
const BookedItem = defineComponent({
    components: {
        BookedItemDetails
    },
    props: {
        date: {
            type: String,
            default: ''
        },
        bookings: {
            type: Array,
            default: () => []
        },
        mode: {
            type: String,
            default: BookingDetailsViewMode.CUSTOMER
        }
    },
    setup(props) {
        return {
            isToday: computed(() => props.date && isDateToday(props.date))
        };
    }
});
export default BookedItem;
