import { defineComponent, computed } from '@vue/composition-api';
import Accordion from '@/components/Accordion.vue';
import BookedItem from '@/Booking/components/BookedItem.vue';
import { BookingDetailsViewMode, BookingTypes } from '@/Booking/booking.const';
const sections = [
    {
        header: {
            title: 'booking.user_bookings.booking_types.upcoming',
            icon: 'circle-check'
        },
        key: BookingTypes.UPCOMING,
        data: null,
        defaultCollapsed: false
    },
    {
        header: {
            title: 'booking.user_bookings.booking_types.requests',
            icon: 'circle-question'
        },
        key: BookingTypes.REQUESTS,
        data: null,
        defaultCollapsed: true
    },
    {
        header: {
            title: 'booking.user_bookings.booking_types.reservations',
            icon: 'clock'
        },
        key: BookingTypes.RESERVATIONS,
        data: null,
        defaultCollapsed: true
    },
    {
        header: {
            title: 'booking.user_bookings.booking_types.archive',
            icon: 'box-archive'
        },
        key: BookingTypes.ARCHIVED,
        data: null,
        defaultCollapsed: true
    }
];
export default defineComponent({
    components: {
        BookedItem,
        Accordion
    },
    props: {
        data: {
            type: Object,
            default: () => { }
        },
        mode: {
            type: String,
            default: BookingDetailsViewMode.CUSTOMER
        }
    },
    setup(props) {
        const dataSections = computed(() => sections.map(({ key, ...section }) => ({
            ...section,
            key,
            data: props.data[key],
            isDisabled: props.data[key]
                ? !Object.keys(props.data[key]).length
                : true
        })));
        return {
            sections: dataSections
        };
    }
});
