import { defineComponent, computed, ref, onUnmounted } from '@vue/composition-api';
import { BookingStatus, BookingType } from '@/generated-types/graphql.types';
import { ExtendedBookingStatus } from '@/Booking/booking.types';
import { BookingRoutes, BookingDetailsViewMode } from '@/Booking/booking.const';
import { getBookingStatus } from '@/Booking/utils/bookings';
import BookingStatusLabel from '@/Booking/components/BookingStatusLabel.vue';
import { calculateRemainingTime, ifLessThenDay, unixFormatted } from '@/util/dates';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    components: {
        BookingStatusLabel
    },
    computed: {
        BookingStatus() {
            return BookingStatus;
        }
    },
    props: {
        booking: {
            type: Object,
            default: null
        },
        mode: {
            type: String,
            default: BookingDetailsViewMode.CUSTOMER
        }
    },
    setup(props) {
        const countDown = ref(undefined);
        let threshold = ref(undefined);
        let interval = ref(undefined);
        let thresholdComingSoon = ref(false);
        const statusLabel = computed(() => getBookingStatus(props?.booking));
        const displayThreshold = computed(() => statusLabel.value &&
            new Array(ExtendedBookingStatus.Pending, ExtendedBookingStatus.Unpaid).includes(statusLabel.value) &&
            !props.booking?.is_afterpay_requested);
        if (displayThreshold.value) {
            thresholdComingSoon = computed(() => !!(props?.booking?.ts_reservation_end &&
                ifLessThenDay(props?.booking?.ts_reservation_end)));
            if (thresholdComingSoon.value) {
                countDown.value = calculateRemainingTime(props?.booking?.ts_reservation_end);
                interval.value = window.setInterval(() => {
                    countDown.value = calculateRemainingTime(props?.booking?.ts_reservation_end);
                }, 60000);
            }
            else {
                threshold = computed(() => unixFormatted(props?.booking?.ts_reservation_end ?? 0, 'DD/MM/YYYY HH:mm'));
            }
        }
        onUnmounted(() => {
            if (interval.value) {
                clearInterval(interval.value);
            }
        });
        return {
            countDown,
            detailsViesModes: BookingDetailsViewMode,
            displayThreshold,
            isOffer: computed(() => props.booking?.booking_type === BookingType.Offer),
            statusLabel,
            thumbnail: computed(() => `${props.booking.space?.space_images?.[0]?.image_url}/tr:w-75`),
            threshold,
            thresholdComingSoon,
            BookingRoutes,
            StatusLabels: ExtendedBookingStatus,
            useGetLocalizedPath
        };
    }
});
